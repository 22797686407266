import LogoShared from "../assets/images/logoShared.png";

interface IProps {
    Logo: string;
    description: string
}

const JackpotComponent = ({Logo, description}: IProps) => {
    return (
        <div className='jackpots__item'>
            <img src={Logo} alt=""/>
            <p>{description}</p>
        </div>
    )
}

export default JackpotComponent;
