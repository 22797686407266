import { get } from './api'

export async function getGameLink(type: string, gameCode?: string) {
  try {
    const response = await get(`url/${type}`, { params: {gameCode}})
    return response?.data?.url;
  } catch (e) {
    console.log(e)
  }
}
