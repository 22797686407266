import React from 'react'
import { Route, Routes } from 'react-router-dom'
import FeaturesList from '@routes/FeaturesList'
import JackpotDetails from '@routes/JackpotDetails'
import { InternalRoute } from '@utilities/enums'
import "../src/styles/main.scss";

function App() {
  return (
      <div className='ehub'>
          <Routes>
              <Route path={InternalRoute.listing} element={<FeaturesList/>}/>
              <Route path={InternalRoute.mwjp} element={<JackpotDetails/>}/>
          </Routes>
      </div>
  )
}

export default App
