import { useNavigate } from 'react-router-dom'
import { startGame } from '@utilities/actions'
import { useState } from 'react'
import { FeatureType } from '@utilities/enums'
import LogoAmount from '../assets/images/logoAmaunt.png'
import LogoHourly from '../assets/images/logoHourly.png'
import LogoNoLimit from '../assets/images/logoNoLimit.png'
import LogoShared from '../assets/images/logoShared.png'
import LogoDaily from '../assets/images/logoDaily.png'
import Glow from '../assets/images/glow.png'
import LogoMWJP from '../assets/images/logoSmMWJP.png'
import JackpotComponent from '../components/JackpotComponent'
import '../styles/components/jackpotDetails.scss'
import { features } from '@utilities/constants'


const JackpotDetails = () => {
  let navigate = useNavigate()
  const feature = features.find(({featureType}) => featureType === FeatureType.mwjp)
  const [loading, setLoading] = useState(false)
  const onClick = async (type: FeatureType) => {
    if (loading) {
      return
    }
    setLoading(true)
    try {
      await startGame({navigate, feature: {featureType: type, gameCode: feature?.gameCode}})
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }
  return (
    <div className='jackpots'>
      <div className='jackpots__logo'>
        <img className='jackpots__glow' src={Glow} alt=""/>
        <img className='jackpots__logo-img' src={LogoMWJP} alt=""/>
      </div>
        <div className='jackpots__content'>
            <JackpotComponent Logo={LogoDaily} description='Guaranteed to drop once a day' />
            <JackpotComponent Logo={LogoHourly} description='Guaranteed to drop once an hour' />
            <JackpotComponent Logo={LogoAmount} description='Guaranteed to drop before a specific amount' />
            <JackpotComponent Logo={LogoNoLimit} description='Plain Progressive' />
            <JackpotComponent Logo={LogoShared} description='70% goes  to JP winner,
                    30% — shared between eligible players' />
        </div>
      <button className='jackpots__play' type={'button'} onClick={() => onClick(FeatureType.mwjp)}>
           <p>PLAY DEMO</p>
      </button>
      <button className='jackpots__play' type={'button'} onClick={() => onClick(FeatureType.mwjp_mft)}>
           <p>PLAY DEMO in MFT</p>
      </button>
    </div>
  )
}

export default JackpotDetails
