import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import configuration from '@utilities/getConfiguration'

export const http = axios.create({
  baseURL: configuration.apiDomain
})

export const post = (endpoint: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
  new Promise((resolve, reject) =>
    http
      .post(endpoint, data, config)
      .then(resolve)
      .catch(e => reject(e.response.data))
  )

export const get = (endpoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
  new Promise((resolve, reject) =>
    http
      .get(endpoint, config)
      .then(resolve)
      .catch(e => reject(e?.response?.data))
  )

export const patch = <T>(endpoint: string, data: T): Promise<AxiosResponse> =>
  new Promise((resolve, reject) =>
    http
      .patch(endpoint, data)
      .then(resolve)
      .catch(e => reject(e.response.data))
  )
