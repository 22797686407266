import { features } from '@utilities/constants'
import { FeatureComponent } from '../components/FeatureComponent'
import SkywindLogo from '../assets/images/logoSkywind.png'

const FeaturesList = () => {
  return (
    <div className='features__main'>
      <div className='features__logo'>
        <img src={SkywindLogo} alt="Skywind logo"/>
      </div>
        <div className='features__content'>
            {
                features.map((feature, index) => <FeatureComponent {...feature} key={index}/>)
            }
        </div>
    </div>
  )
}

export default FeaturesList
