import { FunctionComponent, useState } from 'react'
import { IFeatureProps } from '@utilities/interfaces'
import { useNavigate } from 'react-router-dom'
import { onFeatureClick } from '@utilities/actions'
import {FeatureType} from "@utilities/enums";
import  PrizeDropLogo  from '../assets/images/logoLuckyEnvelopes.png';
import TournamentLogo from '../assets/images/logoTournaments.png';
import MWJPLogo from '../assets/images/logoMWJP.png';
import FreeBetsLogo from '../assets/images/logoFreeBets.png';
import InstantJackpotLogo from '../assets/images/logoInstantJackpot.png'

export const FeatureComponent: FunctionComponent<IFeatureProps> = (feature) => {
  let navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    try {
      await onFeatureClick({navigate, feature})
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  return (
    <div className='features__item' onClick={onClick}>
        {feature.featureType === FeatureType.prizeDrop && <img src={PrizeDropLogo} alt='Prize Drop Logo'/>}
        {feature.featureType === FeatureType.tournament && <img src={TournamentLogo} alt='Tournament Logo'/>}
        {feature.featureType === FeatureType.mwjp && <img src={MWJPLogo} alt='MWJP logo'/>}
        {feature.featureType === FeatureType.instantJackpot && <img src={InstantJackpotLogo} alt='Instant Jackpot logo'/>}
        {feature.featureType === FeatureType.freeBets && <img src={FreeBetsLogo} alt='Free Bets Logo'/>}
    </div>
  )
}
