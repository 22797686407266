import { IFeatureProps } from '@utilities/interfaces'
import { FeatureType, InternalRoute } from '@utilities/enums'

export const features: IFeatureProps[] = [
  {
    featureType: FeatureType.tournament,
    gameCode: "sw_mmu_965"
  },
  {
    featureType: FeatureType.prizeDrop,
    gameCode: "sw_dwtd"
  },
  {
    featureType: FeatureType.mwjp,
    redirectTo: InternalRoute.mwjp,
    gameCode: "sw_tigome"
  },
  {
    featureType: FeatureType.instantJackpot,
    gameCode: "sw_bb"
  }
]