export enum FeatureType {
  tournament = 'tournament',
  prizeDrop = 'prize_drop',
  mwjp = 'jackpot',
  mwjp_mft = 'jackpot_mft',
  freeBets = 'freeBets',
  instantJackpot = 'instant_jackpot'
}

export enum InternalRoute {
  listing = '/',
  mwjp = '/mwjp',
}

export enum RandomType {
  username = 'username',
  password = 'password',
}
