import { IAppConfiguration } from '@utilities/interfaces'

const getEnvParam = (param: string) => {
  try {
    return process && process.env[param]
  } catch (e) {
    return undefined
  }
}

export const getConfiguration = (): IAppConfiguration => {
  return {
    nodeEnv: getEnvParam('REACT_APP_NODE_ENV') as string,
    apiDomain: getEnvParam('REACT_APP_API_DOMAIN') as string,
  }
}

export default getConfiguration()
