import { IFeatureClickProps } from '@utilities/interfaces'
import { getGameLink } from '@utilities/requests'
import randomstring from 'randomstring'
import { RandomType } from '@utilities/enums'

export const generateRandomString = (type: RandomType): string => {
  if (type === RandomType.username) {
    return randomstring.generate({
      length: 10,
      charset: 'alphanumeric',
      capitalization: 'lowercase'
    })
  }
  return randomstring.generate({
    length: 12,
    charset: 'alphanumeric'
  })
}

function openTab(url: string) {
  let newWindow = window.open(url, '_blank');
  if (newWindow) {
    newWindow.opener = null
  } else {
    window.location.assign(url)
  }
}
export const onFeatureClick = async ({navigate, feature}: IFeatureClickProps) => {
  if (feature.redirectTo) {
    openTab(feature.redirectTo)
    return
  }
  await startGame({navigate, feature})
}
export const startGame = async ({feature}: IFeatureClickProps) => {
  const gameLink = await getGameLink(feature.featureType, feature.gameCode)
  if (!gameLink) {
    alert('Game link not found')
    return
  }
  openTab(gameLink)
}